$fontSizeDefault: 2.14vh;
/************************************/

$colorBlack: #222328;
$colorGreen: green;
$colorWhite: white;

div#screen {
  width: 100vh;
}

input.mapfield {
  font-family: 'PT Mono', 'Courier New';
  font-size: $fontSizeDefault;
  background-color: $colorBlack;
  color: #00ff00;
  border: none;
  height: 2.5vh;
  box-shadow: 0px 0px 2px 0px $colorWhite;
  text-transform: uppercase;
}

pre {
  font-family: 'PT Mono', 'Courier New';
  font-size: $fontSizeDefault;
  margin: 0px;
  padding: 0px;
  padding-left: 0px;
}

.bluefg {
  color: deepskyblue !important;
}

.pinkfg {
  color: #ff1498 !important;
}

.yellowfg {
  color: #ffff00 !important;
}

.norm {
  font-weight: normal;
  border-bottom-style: none;
}
.brt {
  font-weight: normal;
  border-bottom-style: none;
  color: $colorWhite;
}

.blink {
  font-style: italic;
  border-bottom-style: none;
}

.underline {
  border-bottom: 1px solid;
  height: 2.5vh;
}

pre.over {
} /* border-top: medium solid */
pre.under {
} /* border-bottom: medium solid */
pre.left {
} /* border-left: medium solid */
pre.right {
} /* border-right: medium solid */
pre.box {
}

@media only screen and (max-width: 3840px) {
}
@media only screen and (max-width: 2560px) {
}
@media only screen and (max-width: 1920px) {
}
@media only screen and (max-width: 1366px) {
}
/* border: medium solid */

.redfg {
  color: red !important;
}

.greenfg {
  color: #00ff00 !important;
}
.turquoisefg {
  color: #00ffff !important;
}

.blackfg {
  color: black !important;
}
.deepbluefg {
  color: navy !important;
}
.orangefg {
  color: #ffa500 !important;
}
.purplefg {
  color: purple !important;
}
.palegreenfg {
  color: #98fb98 !important;
}
.paleturquoisefg {
  color: #afeeee !important;
}
.greyfg {
  color: gray !important;
}
.whitefg {
  color: $colorWhite !important;
}
.neutral1fg {
  color: $colorWhite !important;
}
.neutral2fg {
  color: $colorWhite !important;
}

/* Do NOT mark as !important, so this can be overridden */
.defaultfg {
  color: $colorWhite;
}

/* The same colors as defined above but for reverse mode */
.bluefg.reverse {
  color: black !important;
  background-color: deepskyblue !important;
}
.redfg.reverse {
  color: black !important;
  background-color: red !important;
}
.pinkfg.reverse {
  color: black !important;
  background-color: #ff1498 !important;
}
.greenfg.reverse {
  color: black !important;
  background-color: #00ff00 !important;
}
.turquoisefg.reverse {
  color: black !important;
  background-color: #00ffff !important;
}
.yellowfg.reverse {
  color: black !important;
  background-color: #ffff00 !important;
}
.blackfg.reverse {
  color: black !important;
  background-color: black !important;
}
.deepbluefg.reverse {
  color: black !important;
  background-color: navy !important;
}
.orangefg.reverse {
  color: black !important;
  background-color: #ffa500 !important;
}
.purplefg.reverse {
  color: black !important;
  background-color: purple !important;
}
.palegreenfg.reverse {
  color: black !important;
  background-color: #98fb98 !important;
}
.paleturquoisefg.reverse {
  color: black !important;
  background-color: #afeeee !important;
}
.greyfg.reverse {
  color: black !important;
  background-color: gray !important;
}
.whitefg.reverse {
  color: black !important;
  background-color: $colorWhite !important;
}
.neutral1fg.reverse {
  color: black !important;
  background-color: $colorWhite !important;
}
.neutral2fg.reverse {
  color: black !important;
  background-color: $colorWhite !important;
}

@mixin styling($rowOffsett,$colOffsett, $rowHeight, $colWidth, $colLen, $lenOffsett) {
  ///*
  .line1 {
    position: absolute;
    top: calc(1 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line2 {
    position: absolute;
    top: calc(2 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line3 {
    position: absolute;
    top: calc(3 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line4 {
    position: absolute;
    top: calc(4 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line5 {
    position: absolute;
    top: calc(5 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line6 {
    position: absolute;
    top: calc(6 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line7 {
    position: absolute;
    top: calc(7 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line8 {
    position: absolute;
    top: calc(8 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line9 {
    position: absolute;
    top: calc(9 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line10 {
    position: absolute;
    top: calc(10 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line11 {
    position: absolute;
    top: calc(11 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line12 {
    position: absolute;
    top: calc(12 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line13 {
    position: absolute;
    top: calc(13 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line14 {
    position: absolute;
    top: calc(14 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line15 {
    position: absolute;
    top: calc(15 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line16 {
    position: absolute;
    top: calc(16 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line17 {
    position: absolute;
    top: calc(17 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line18 {
    position: absolute;
    top: calc(18 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line19 {
    position: absolute;
    top: calc(19 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line20 {
    position: absolute;
    top: calc(20 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line21 {
    position: absolute;
    top: calc(21 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line22 {
    position: absolute;
    top: calc(22 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line23 {
    position: absolute;
    top: calc(23 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line24 {
    position: absolute;
    top: calc(24 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  .line25 {
    position: absolute;
    top: calc(25 * #{$rowHeight}vh + #{$rowOffsett}vh);
  }
  /**/

  //.col1 { left: 0.3em; }

  .col1 {
    left: calc(1 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col2 {
    left: calc(2 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col3 {
    left: calc(3 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col4 {
    left: calc(4 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col5 {
    left: calc(5 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col6 {
    left: calc(6 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col7 {
    left: calc(7 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col8 {
    left: calc(8 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col9 {
    left: calc(9 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col10 {
    left: calc(10 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col11 {
    left: calc(11 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col12 {
    left: calc(12 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col13 {
    left: calc(13 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col14 {
    left: calc(14 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col15 {
    left: calc(15 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col16 {
    left: calc(16 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col17 {
    left: calc(17 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col18 {
    left: calc(18 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col19 {
    left: calc(19 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col20 {
    left: calc(20 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col21 {
    left: calc(21 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col22 {
    left: calc(22 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col23 {
    left: calc(23 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col24 {
    left: calc(24 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col25 {
    left: calc(25 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col26 {
    left: calc(26 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col27 {
    left: calc(27 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col28 {
    left: calc(28 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col29 {
    left: calc(29 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col30 {
    left: calc(30 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col31 {
    left: calc(31 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col32 {
    left: calc(32 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col33 {
    left: calc(33 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col34 {
    left: calc(34 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col35 {
    left: calc(35 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col36 {
    left: calc(36 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col37 {
    left: calc(37 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col38 {
    left: calc(38 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col39 {
    left: calc(39 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col40 {
    left: calc(40 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col41 {
    left: calc(41 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col42 {
    left: calc(42 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col43 {
    left: calc(43 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col44 {
    left: calc(44 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col45 {
    left: calc(45 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col46 {
    left: calc(46 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col47 {
    left: calc(47 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col48 {
    left: calc(48 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col49 {
    left: calc(49 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col50 {
    left: calc(50 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col51 {
    left: calc(51 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col52 {
    left: calc(52 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col53 {
    left: calc(53 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col54 {
    left: calc(54 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col55 {
    left: calc(55 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col56 {
    left: calc(56 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col57 {
    left: calc(57 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col58 {
    left: calc(58 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col59 {
    left: calc(59 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col60 {
    left: calc(60 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col61 {
    left: calc(61 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col62 {
    left: calc(62 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col63 {
    left: calc(63 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col64 {
    left: calc(64 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col65 {
    left: calc(65 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col66 {
    left: calc(66 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col67 {
    left: calc(67 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col68 {
    left: calc(68 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col69 {
    left: calc(69 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col70 {
    left: calc(70 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col71 {
    left: calc(71 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col72 {
    left: calc(72 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col73 {
    left: calc(73 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col74 {
    left: calc(74 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col75 {
    left: calc(75 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col76 {
    left: calc(76 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col77 {
    left: calc(77 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col78 {
    left: calc(78 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col79 {
    left: calc(79 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  .col80 {
    left: calc(80 * #{$colWidth}vh + #{$colOffsett}vh);
  }
  /******************************************************************************************/
  .len1 {
    width: calc(1 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len2 {
    width: calc(2 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len3 {
    width: calc(3 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len4 {
    width: calc(4 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len5 {
    width: calc(5 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len6 {
    width: calc(6 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len7 {
    width: calc(7 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len8 {
    width: calc(8 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len9 {
    width: calc(9 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len10 {
    width: calc(10 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len11 {
    width: calc(11 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len12 {
    width: calc(12 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len13 {
    width: calc(13 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len14 {
    width: calc(14 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len15 {
    width: calc(15 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len16 {
    width: calc(16 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len17 {
    width: calc(17 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len18 {
    width: calc(18 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len19 {
    width: calc(19 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len20 {
    width: calc(20 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len21 {
    width: calc(21 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len22 {
    width: calc(22 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len23 {
    width: calc(23 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len24 {
    width: calc(24 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len25 {
    width: calc(25 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len26 {
    width: calc(26 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len27 {
    width: calc(27 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len28 {
    width: calc(28 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len29 {
    width: calc(29 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len30 {
    width: calc(30 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len31 {
    width: calc(31 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len32 {
    width: calc(32 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len33 {
    width: calc(33 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len34 {
    width: calc(34 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len35 {
    width: calc(35 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len36 {
    width: calc(36 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len37 {
    width: calc(37 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len38 {
    width: calc(38 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len39 {
    width: calc(39 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len40 {
    width: calc(40 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len41 {
    width: calc(41 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len42 {
    width: calc(42 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len43 {
    width: calc(43 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len44 {
    width: calc(44 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len45 {
    width: calc(45 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len46 {
    width: calc(46 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len47 {
    width: calc(47 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len48 {
    width: calc(48 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len49 {
    width: calc(49 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len50 {
    width: calc(50 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len51 {
    width: calc(51 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len52 {
    width: calc(52 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len53 {
    width: calc(53 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len54 {
    width: calc(54 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len55 {
    width: calc(55 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len56 {
    width: calc(56 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len57 {
    width: calc(57 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len58 {
    width: calc(58 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len59 {
    width: calc(59 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len60 {
    width: calc(60 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len61 {
    width: calc(61 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len62 {
    width: calc(62 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len63 {
    width: calc(63 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len64 {
    width: calc(64 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len65 {
    width: calc(65 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len66 {
    width: calc(66 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len67 {
    width: calc(67 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len68 {
    width: calc(68 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len69 {
    width: calc(69 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len70 {
    width: calc(70 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len71 {
    width: calc(71 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len72 {
    width: calc(72 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len73 {
    width: calc(73 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len74 {
    width: calc(74 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len75 {
    width: calc(75 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len76 {
    width: calc(76 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len77 {
    width: calc(77 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len78 {
    width: calc(78 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len79 {
    width: calc(79 * #{$colLen}vh + #{$lenOffsett}vh);
  }
  .len80 {
    width: calc(80 * #{$colLen}vh + #{$lenOffsett}vh);
  }
}

/* default */
@include styling($rowOffsett: -2.4, $colOffsett: 1, $rowHeight: 3.6, $colWidth: 1.3, $colLen: 1.3, $lenOffsett: 0.9);



input[title]:hover::after {
  content: attr(title);
  position: absolute;
  top: -100%;
  left: -50;
}

@media print {
  body * {
    visibility: hidden;
    zoom: 96%;
    margin-left: -1;
    margin-top: 10mm;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  .bluefg {
    color: blue !important;
  }

  .pinkfg {
    color: black !important;
  }

  .yellowfg {
    color: black !important;
  }

  .redfg {
    color: black !important;
  }

  .greenfg {
    color: black !important;
  }
  .turquoisefg {
    color: black !important;
  }

  .blackfg {
    color: black !important;
  }
  .deepbluefg {
    color: navy !important;
  }
  .orangefg {
    color: black !important;
  }
  .purplefg {
    color: black !important;
  }
  .palegreenfg {
    color: black !important;
  }
  .paleturquoisefg {
    color: black !important;
  }
  .greyfg {
    color: black !important;
  }

  .whitefg {
    color: black !important;
  }
  .neutral1fg {
    color: black !important;
  }
  .neutral2fg {
    color: black !important;
  }

  .defaultfg {
    color: black;
  }

  $colWidth: 1.4;
  $colLen: 1.5;
  $lenOffsett: 1.9;
}
